import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [12,[2]],
		"/(app)/account/billing": [13,[2,3]],
		"/(app)/account/integrations": [14,[2,3]],
		"/(app)/account/notifications": [15,[2,3]],
		"/(app)/account/organization": [16,[2,3]],
		"/(app)/account/profile": [17,[2,3]],
		"/(app)/account/update-email": [18,[2,3,4]],
		"/(app)/account/update-password": [~19,[2,3,5]],
		"/(app)/admin": [~20,[2]],
		"/(app)/admin/users": [21,[2]],
		"/(app)/admin/users/[id]": [22,[2]],
		"/(app)/calendar": [23,[2]],
		"/(app)/courses": [24,[2,6]],
		"/(app)/courses/mux": [25,[2,6]],
		"/(app)/email": [~26,[2]],
		"/(app)/feed": [~27,[2]],
		"/(auth)/forgot-password": [55,[9]],
		"/(app)/gpt": [28,[2]],
		"/(app)/kanban": [29,[2]],
		"/(auth)/login": [56,[9]],
		"/(app)/logout": [~30,[2]],
		"/(app)/map": [31,[2]],
		"/(app)/messages": [32,[2]],
		"/(app)/onboarding": [33,[2,7]],
		"/(app)/playground": [34,[2,8]],
		"/(app)/playground/charts": [35,[2,8]],
		"/(app)/playground/code": [36,[2,8]],
		"/(app)/playground/documents": [37,[2,8]],
		"/(app)/playground/form": [~38,[2,8]],
		"/(app)/playground/jobs/new": [40,[2,8]],
		"/(app)/playground/jobs/[id]/edit": [39,[2,8]],
		"/(app)/playground/super-form": [~41,[2,8]],
		"/(app)/playground/super-form/users": [~42,[2,8]],
		"/(app)/playground/test": [43,[2,8]],
		"/(app)/pricing": [~44,[2]],
		"/(app)/products": [45,[2]],
		"/(app)/products/id": [47,[2]],
		"/(app)/products/[id]": [46,[2]],
		"/(app)/projects": [~48,[2]],
		"/(app)/projects/[id]": [49,[2]],
		"/(app)/realtime": [50,[2]],
		"/(app)/realtime/presence": [51,[2]],
		"/(auth)/register": [57,[9]],
		"/sentry-example": [58],
		"/(app)/stripe/order": [52,[2]],
		"/(app)/stripe/payment": [53,[2]],
		"/(app)/stripe/success": [~54,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';
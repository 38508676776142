import * as Sentry from '@sentry/sveltekit'

import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import { log } from '$lib/utils'
import { dev } from '$app/environment'

Sentry.init({
	enabled: !dev,
	release: __version__,
	environment: 'client',
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: dev ? 0 : 1.0,
	// For instance, initialize Session Replay:
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [new Sentry.Replay()],
})

const myErrorHandler = ({ error, event }) => {
	log.hooks('hooks.client.ts')
	console.error('An error occurred on the client side:', error, event)
}

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)
